<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols="6">
                    <b-form-group
                        v-if="'markAsPaidBy' in invoice"
                        class="row"
                        label="Mark as paid by"
                        label-for="h-status"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input disabled
                                      :model-value="invoice.markAsPaidBy.givenName + ' ' + invoice.markAsPaidBy.familyName"
                                      id="closed" type="text"/>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.branchSelect.$errors.length }"
                        :label="$t('forms.branch')"
                        label-for="h-branch"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="h-branch"
                            v-model="branchSelect"
                            :options="branches"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.branchSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.invoice.dateIssued.$errors.length }"
                        :label="$t('forms.dateIssued')"
                        label-for="h-date"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="date"
                            v-model="invoice.dateIssued"
                            class="form-input"
                            :enableTimePicker="false"
                            format="dd.MM.yyyy"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.invoice.dateIssued.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.supplyOfGoodOrServices')"
                        label-for="supply"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="supply"
                            v-model="invoice.supplyOfGoodOrServices"
                            class="form-input"
                            :enableTimePicker="false"
                            format="dd.MM.yyyy"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.invoice.supplyOfGoodOrServices.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.dueDate')"
                        label-for="dueDate"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="dueDate"
                            v-model="invoice.dueDate"
                            class="form-input"
                            :enableTimePicker="false"
                            format="dd.MM.yyyy"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.invoice.dueDate.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.paymentInterval')"
                        label-for="paymentInterval"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="paymentInterval"
                            v-model="invoice.paymentInterval"
                            class="form-input"
                            :enableTimePicker="false"
                            format="dd.MM.yyyy"
                        />
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.invoice.invoiceNumber.$errors.length }"
                        :label="$t('forms.number')"
                        label-for="number"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="invoice.invoiceNumber"
                            id="number"
                            type="text"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.invoice.invoiceNumber.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.invoice.customer.$errors.length }"
                        :label="$t('forms.company')"
                        label-for="company"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="invoice.customer"
                            id="company"
                            type="text"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.invoice.customer.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        v-if="'id' in invoice && !isOwner()"
                        class="row"
                        :label="$t('forms.paid')"
                        label-for="paid"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-checkbox v-model="invoice.paid" id="h-closed" switch/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="Outsourced"
                        label-for="paid"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-checkbox v-model="invoice.outsourced" id="h-closed" switch/>
                    </b-form-group>
                    <b-form-group
                        v-if="'id' in invoice && !isOwner()"
                        class="row"
                        :label="$t('forms.approved')"
                        label-for="approved"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-checkbox v-model="invoice.approved" id="h-closed" switch/>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group
                        v-if="'approvedBy' in invoice"
                        class="row"
                        label="Approved by"
                        label-for="h-status"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input disabled
                                      :model-value="invoice.approvedBy.givenName + ' ' + invoice.approvedBy.familyName"
                                      id="closed" type="text"/>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.categorySelect.$errors.length }"
                        :label="$t('forms.category')"
                        label-for="h-category"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="h-category"
                            v-model="categorySelect"
                            :options="categories"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.categorySelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.description')"
                        label-for="description"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea
                            v-model="invoice.description"
                            id="description"
                            type="number"
                        />
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.invoice.amount.$errors.length }"
                        :label="$t('forms.amount')"
                        label-for="amount"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="invoice.amount"
                            id="description"
                            type="text"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.invoice.amount.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.currencySelect.$errors.length }"
                        :label="$t('forms.currency')"
                        label-for="h-currency"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="h-currency"
                            v-model="currencySelect"
                            :options="currencies"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.currencySelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.localCurrency')"
                        label-for="localCurrency"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="localCurrency"
                            v-model="localCurrencySelect"
                            :options="currencies"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.forApprovalBy')"
                        label-for="forApprovalBy"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="forApprovalBy"
                            v-model="departmentSelect"
                            :options="departments"
                        />
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.stationSelect.$errors.length }"
                        :label="$t('forms.station')"
                        label-for="h-station"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="h-station"
                            v-model="stationSelect"
                            :options="stations"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stationSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.file')"
                        label-for="h-closed"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <BootstrapIcon v-if="fileURL" icon="eye" size="1x"/>
                        <a v-if="fileURL" :href="fileURL" target="_blank">
                            {{ $t("forms.viewFile") }}
                        </a>
                        <document-upload v-if="$helper.userCapability('update',$route)" :simple="true"
                                         @fileUploaded="setFile"/>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import {decimal, required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import vSelect from "vue-select";
import DocumentUpload from "@/components/DocumentUpload.vue";
import {getUserData} from "@/auth/utils";

export default {
    components: {
        Datepicker,
        vSelect,
        DocumentUpload,
    },
    props: {
        invoiceObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getEnumList(
            ["invoice_status", "currency", "invoice_paid_by"],
            this.statuses,
            this.currencies,
            this.paidBy
        );
        this.getResourceList(this.$InvoiceCategories, this.categories);
        this.getResourceList(this.$Branches, this.branches);
        this.getResourceList(this.$Stations, this.stations);
        this.getResourceList(this.$Departments, this.departments);
        if ("id" in this.invoice) {
            if (this.invoice.branch) {
                this.branchSelect = {
                    label: this.invoice.branch.name,
                    id: this.invoice.branch["@id"],
                };
            }
            if (this.invoice.station) {
                this.stationSelect = {
                    label: this.invoice.station.name,
                    id: this.invoice.station["@id"],
                };
            }
            this.statusSelect = {
                label: this.$helper.getEnumTranslation(
                    "invoice_status",
                    this.invoice.status,
                    this.$i18n.locale
                ),
                id: this.invoice.status,
            };
            if (this.invoice.category) {
                this.categorySelect = {
                    label: this.invoice.category.name,
                    id: this.invoice.category["@id"],
                };
            }
            this.currencySelect = {
                label: this.$helper.getEnumTranslation(
                    "currency",
                    this.invoice.currency,
                    this.$i18n.locale
                ),
                id: this.invoice.currency,
            };
            if ("department" in this.invoice) {
                this.departmentSelect = {
                    label: this.invoice.department.name,
                    id: this.invoice.department["@id"],
                };
            }
            if ("localCurrency" in this.invoice) {
                this.localCurrencySelect = {
                    label: this.$helper.getEnumTranslation(
                        "currency",
                        this.invoice.localCurrency,
                        this.$i18n.locale
                    ),
                    id: this.invoice.localCurrency,
                };
            }
        }
        if (this.invoice.url) {
            this.fileURL = this.invoice.url;
        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            stationSelect: {required},
            categorySelect: {required},
            currencySelect: {required},
            branchSelect: {required},
            invoice: {
                dateIssued: {required},
                supplyOfGoodOrServices: {required},
                dueDate: {required},
                amount: {required, decimal},
                customer: {required},
                invoiceNumber: {required},
            },
        };
    },
    data() {
        return {
            invoice: this.invoiceObject,
            statuses: [],
            categories: [],
            currencies: [],
            branches: [],
            stations: [],
            departments: [],
            paidBy: [],
            statusSelect: {},
            branchSelect: {},
            stationSelect: {},
            categorySelect: {},
            currencySelect: {},
            localCurrencySelect: {},
            departmentSelect: {},
            fileURL: null,
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        setFile(data) {
            if ("file" in this.invoice) {
                this.deleteByUrl(this.$Files, this.invoice.file, null);
            }
            this.invoice.file = data["@id"];
            this.fileURL = data.url;
        },
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                this.invoice.station = this.stationSelect.id;
                this.invoice.category = this.categorySelect.id;
                this.invoice.currency = this.currencySelect.id;
                this.invoice.branch = this.branchSelect.id;
                if (this.departmentSelect.id) {
                    this.invoice.department = this.departmentSelect.id;
                }
                if (this.localCurrencySelect.id) {
                    this.invoice.localCurrency = this.localCurrencySelect.id;
                }
                if (this.action === "add") {
                    this.invoice.type = "INCOMING";
                    if (this.isOutgoing()) {
                        this.invoice.type = "OUTGOING";
                    }
                    this.create(
                        this.$Invoices,
                        this.invoice,
                        this.$t("messages.invoiceCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
                if (this.action === "update") {
                    this.$emit("clearAction");
                    this.update(
                        this.$Invoices,
                        this.invoice.id,
                        this.invoice,
                        this.$t("messages.invoiceUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },

        isOutgoing() {
            return this.$route.fullPath.includes("outgoing");
        },
        isOwner() {
            return "employee" in this.invoice &&
                getUserData().id === this.invoice.employee.id;
        },
        success() {
            if (this.isOutgoing()) {
                this.$router.push(`/invoices/outgoing`);
            } else {
                this.$router.push(`/invoices/incoming`);
            }
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

